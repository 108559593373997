<!--8个人中心-14团队成员-4经纪人-->
<template>
  <div class="team_page">
    <div class="team_main">
      <div class="team_title">{{ getPosition(userInfo.position) }}</div>
      <!--team_list start-->
      <div class="team_list">
        <!--team_item start-->
        <div class="team_item" v-for="(item, index) in teamList" :key="index">
          <div class="team_box">
            <div class="team_links">
              <router-link
              v-if="userInfo.position != 4 && userInfo.position != 3"
                class="color_blue links-flex"
                :to="getUrl(item)"
              >
                <img
                  class="links-icon"
                  src="../../assets/image/personage/team1.png"
                />
                <span>查看下级</span>
              </router-link>
            </div>
            <div class="team_hd">
              <div class="hd_img">
                <img :src="item.image" />
              </div>
              <div class="hd_txt">
                <div class="name">{{ item.name }}</div>
                <div class="flex-c">
                  <div class="flex">
                    <img
                      class="icon"
                      src="../../assets/image/personage/team2.png"
                    />
                    <span class="cc">{{ item.job_number }}</span>
                  </div>
                  <div class="flex">
                    <img
                      class="icon"
                      src="../../assets/image/personage/team3.png"
                    />
                    <span class="cc">{{ item.mobile }}</span>
                  </div>
                  <div class="flex" v-if="userInfo.position != 1">
                    <img
                      class="icon"
                      src="../../assets/image/personage/team4.png"
                    />
                    <span class="cc">{{ item.dian_name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="team_bd">
              <div class="li">
                <div class="li-con">
                  <span class="color-6">维护方:</span> {{ item.weihu_count }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">信息方:</span> {{ item.xinxi_count }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">考勤记录:</span> {{ item.kaoqin }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">图片方:</span> {{ item.image_count }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">委托方:</span> {{ item.weituo_count }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">考核记录:</span>
                  {{ item.examine_records }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">钥匙方:</span> {{ item.keys_count }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">总业绩:</span>
                  {{ item.total_performance }}
                </div>
              </div>
              <div class="li">
                <div class="li-con">
                  <span class="color-6">私客数量:</span> {{ item.client_count }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--team_item end-->
      </div>
      <!--team_list end-->
      <div class="paging" v-if="teamList.length != 0">
        <el-pagination
          background
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import index from '@/common/index'
export default {
  name: "Team",
   mixins: [index],
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      page: 1,
      total: 0,
      teamList: [], //团队列表
    };
  },
  methods: {
    getUrl (item) {
      let url = ''
      switch (this.userInfo.position) {
        case 1:
          url = `/personage/teamShop?dian_id=${item.dian_id}&city=${item.city}&dian_name=${item.dian_name}`
          break;
         case 2:
          url = `/personage/teamManager?city=${item.city}&name=${item.name}&manager_id=${item.job_number}`
          break;
        default:
          break;
      }
      return url
    },
    //获取团队列表
    getTeamList() {
      this.$axios
        .teamList({
          page: this.page,
        })
        .then((res) => {
          this.teamList = res.data.data;
          this.total = res.data.total;
        });
    },
  },
  created() {
    this.getTeamList();
  },
};
</script>

<style scoped lang="less">
.agree_pos {
  padding: 30px 30px;
  color: #666;
}
.links-flex {
  display: flex;
  align-items: center;
}
.links-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.team_main {
  background: #fff;
  padding-bottom: 30px;
  padding-top: 15px;
}
.team_title {
  padding: 15px 30px 15px 40px;
  font-size: 18px;
  position: relative;
}
.team_title:before {
  display: block;
  left: 30px;
  top: 50%;
  margin-top: -10px;
  background: #3273f6;
  width: 4px;
  height: 20px;
  border-radius: 2px;
  position: absolute;
  content: "";
}
.team_list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  .team_item {
    width: 50%;
  }
  .team_box {
    margin: 10px 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 0px rgba(189, 189, 189, 0.16);
    padding: 20px;
    position: relative;
  }
  .team_links {
    position: absolute;
    right: 20px;
    top: 15px;
  }
  .team_hd {
    display: flex;
    .hd_img {
      width: 56px;
      min-width: 56px;
      max-width: 56px;
    }
    .hd_img img {
      width: 56px;
      height: 56px;
      border-radius: 100%;
    }
    .hd_txt {
      width: 100%;
      .name {
        font-size: 18px;
        margin-left: 20px;
      }
      .c {
        color: #666;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .flex-c {
        display: flex;
        font-size: 14px;
        color: #666;
        margin: 10px 0px;
        flex-wrap: wrap;
        width: 100%;
        margin-left: 20px;
        .flex {
          margin: 4px 0px;
          justify-content: flex-start;
          min-width: 40%;
          align-items: center;
        }
      }
    }
  }
  .team_bd {
    border-top: 1px solid #ebebeb;
    margin: 0 -5px;
    padding-top: 10px;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    .li {
      width: 33.33333%;
    }
    .li-con {
      margin: 5px;
      color: #ff752a;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
